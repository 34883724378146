/* eslint-disable no-mixed-spaces-and-tabs */
// TODO - Type 'any' needs to be fixed.
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import {subMinutes} from "date-fns";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import startOfWeek from "date-fns/startOfWeek";
import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useMediaQuery} from "react-responsive";

import {JOB, SKILLS} from "../../assets/constants";
import Error from "../../components/Error";
import Availability from "../scheduler/grid-scheduler/availability";
import {dayHeaderHeight, minTimeSlotHeight,} from "../scheduler/grid-scheduler/constants";
import MemoizedDayColumn from "../scheduler/grid-scheduler/memoized-day-column";
import MemoizedDayColumnHeader from "../scheduler/grid-scheduler/memoized-day-column-header";
import {DayHeader, TimeLabel, TimeSlotHeader,} from "../scheduler/grid-scheduler/styled-components";
import {getDayNumber, getLocalTimeZone, hours,} from "../scheduler/grid-scheduler/utils";

function AvailabilityMap({
  shiftsByFilter,
  value,
  changeWeekAvailabilityStatus,
  height,
}: any) {
  const timeSlotHeight: number = useMemo(() => {
    const minHeight = 8; // This is availability, we don't need min height here
    // overall screen height without filter and day header divided by number of time slots in a day
    const returnValue = (height - dayHeaderHeight) / 26;
    return returnValue < minHeight ? minHeight : returnValue;
  }, [height]);

  const [formattedData, setFormattedData] = useState([]);
  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(false);

  const weekStartDay = useSelector(
    (state: any) => state.user?.userData?.weekStartDay
  );

  const currentDate = useSelector(
    (state: any) => state.supervisor?.schedulerData?.currentDate
  );

  const startingDay = useMemo(
    () =>
      startOfWeek(new Date(currentDate), {
        weekStartsOn: getDayNumber(weekStartDay),
      }),
    [weekStartDay, currentDate]
  );

  useMediaQuery({ maxWidth: 1224 }, undefined, () =>
    setIsDrawerCollapsed(!isDrawerCollapsed)
  );

  const fetchFilterAndShiftDataStatus = useSelector(
    (state: any) => state.supervisor?.fetchFilterAndShiftData?.status
  );

  const getHourlyWorkerCountStatus = useSelector(
    (state: any) => state.supervisor?.getHourlyWorkerCount?.status
  );

  const fetchInitialDataErrorMessage = useSelector(
    (state: any) => state.supervisor?.fetchInitialData?.errorMessage
  );

  const allAvailabilityByJob = useSelector(
    (state: any) => state.supervisor.masterData.availabilityDataByJob
  );

  const allAvailabilityBySkill = useSelector(
    (state: any) => state.supervisor.masterData.availabilityDataBySkill
  );

  const allJobRoles = useSelector(
    (state: any) => state.supervisor.filterData.allJobRoles
  );
  const allSkills = useSelector(
    (state: any) => state.supervisor.filterData.allSkills
  );

  useEffect(() => {
    if (value !== 1) {
      if (shiftsByFilter === JOB) {
        if (allAvailabilityByJob) {
          const filteredAvailabilityByJob = allAvailabilityByJob.filter(
            (availabilityByJob: any) =>
              availabilityByJob.entityId === parseInt(value)
          );
          const dataByJob = filteredAvailabilityByJob.map((itemByJob: any) => {
            const resByJob = allJobRoles.find(
              (job: any) => job.id === itemByJob.entityId
            );
            const endDateByJob = new Date(itemByJob.endDate);
            const newItemByJob = { ...itemByJob };
            newItemByJob.endDate = subMinutes(endDateByJob, 1).toISOString();
            return { ...resByJob, ...newItemByJob };
          });

          const newFormattedDataByJob = dataByJob.map(function (item: any) {
            delete item.id;
            return item;
          });
          // Using maximum number of workers instead of max worker
          const maxNumberOfWorkers =
            newFormattedDataByJob.length > 0
              ? Math.max(
                  ...newFormattedDataByJob.map(
                    (item: any) => item.numberOfWorkers
                  )
                )
              : 0;
          const finalFormattedDataByJob = newFormattedDataByJob.map(
            (item: any) => {
              return {
                ...item,
                maxWorkers: maxNumberOfWorkers,
              };
            }
          );
          setFormattedData(finalFormattedDataByJob);
        }
      } else if (shiftsByFilter === SKILLS) {
        if (allAvailabilityBySkill) {
          const filteredAvailabilityBySkill = allAvailabilityBySkill.filter(
            (availabilityBySkill: any) =>
              availabilityBySkill.entityId === parseInt(value)
          );
          const dataBySkill = filteredAvailabilityBySkill.map(
            (itemBySkill: any) => {
              const resBySkill = allSkills.find(
                (skill: any) => skill.id === itemBySkill.entityId
              );
              const endDateBySkill = new Date(itemBySkill.endDate);
              const newItemBySkill = { ...itemBySkill };
              newItemBySkill.endDate = subMinutes(
                endDateBySkill,
                1
              ).toISOString();
              return { ...resBySkill, ...newItemBySkill };
            }
          );

          const formattedDataBySkill = dataBySkill.map(function (item: any) {
            delete item.id;
            return item;
          });
          // Using maximum number of workers instead of max worker
          const maxNumberOfWorkers =
            formattedDataBySkill.length > 0
              ? Math.max(
                  ...formattedDataBySkill.map(
                    (item: any) => item.numberOfWorkers
                  )
                )
              : 0;
          const finalFormattedDataBySkill = formattedDataBySkill.map(
            (item: any) => {
              return {
                ...item,
                maxWorkers: maxNumberOfWorkers,
              };
            }
          );
          setFormattedData(finalFormattedDataBySkill);
        }
      }
    }
  }, [
    value,
    shiftsByFilter,
    allAvailabilityByJob,
    allAvailabilityBySkill,
    allJobRoles,
    allSkills,
  ]);

  const getDataByHourAndDay = (dayIndex: number, hourIndex: number) => {
    const data = formattedData.find(
      (item: any) =>
        isSameDay(addDays(startingDay, dayIndex), new Date(item.startDate)) &&
        new Date(item.startDate).getHours() === hourIndex
    );
    return data ?? { numberOfWorkers: 0, maxWorkers: 0, hexColor: "" };
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: height,
      }}
    >
      {fetchFilterAndShiftDataStatus === "error" && (
        <Error errorMessage={fetchInitialDataErrorMessage} />
      )}
      {getHourlyWorkerCountStatus === "error" && (
        <Error errorMessage={fetchInitialDataErrorMessage} />
      )}
      {(fetchFilterAndShiftDataStatus === "pending" ||
        getHourlyWorkerCountStatus === "pending") && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <CircularProgress />
            <Box mt={1}>
              <Typography>Loading...</Typography>
            </Box>
          </Box>
        </Box>
      )}
      {fetchFilterAndShiftDataStatus === "fulfilled" &&
        getHourlyWorkerCountStatus !== "pending" &&
        getHourlyWorkerCountStatus !== "error" && (
          <>
            {changeWeekAvailabilityStatus === "pending" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <CircularProgress />
                <Box mt={1}>
                  <Typography>Loading...</Typography>
                </Box>
              </Box>
            ) : (
              <Box key={`scheduler-${shiftsByFilter}`}>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "flex-start",
                  }}
                >
                  {/* Time Labels and Indicators */}
                  <Box
                    sx={{
                      minWidth: "80px",
                      maxWidth: "80px",
                      zIndex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        position: "sticky",
                        top: 0,
                        height: `${dayHeaderHeight}px`,
                        minHeight: `${dayHeaderHeight}px`,
                        backgroundColor: "rgba(255,255,255)",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "center",
                        zIndex: 1001,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.7rem",
                          lineHeight: 1.66,
                          fontWeight: 400,
                          fontFamily: "Roboto",
                          color: "rgba(0,0,0,0.3)",
                          textAlign: "right",
                        }}
                      >
                        {getLocalTimeZone()}
                      </Typography>
                    </Box>
                    <TimeSlotHeader
                      height={timeSlotHeight}
                      key={"time-slot-header-first"}
                    />
                    {hours.map((hour, index) => (
                      <TimeSlotHeader
                        height={timeSlotHeight}
                        key={`time-slot-header-${index}`}
                      >
                        <TimeLabel>
                          <Box
                            sx={{
                              width: "100%",
                              marginRight: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "0.7rem",
                                lineHeight: 1.66,
                                fontWeight: 400,
                                fontFamily: "Roboto",
                                marginTop: -1,
                                marginRight: 1,
                                color: "rgba(0,0,0,0.6)",
                                textAlign: "right",
                              }}
                            >
                              {hour}
                            </Typography>
                          </Box>
                        </TimeLabel>
                      </TimeSlotHeader>
                    ))}
                    <TimeSlotHeader
                      height={timeSlotHeight}
                      key={"time-slot-header-last"}
                    >
                      <TimeLabel>
                        <Box
                          sx={{
                            width: "100%",
                            marginRight: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.7rem",
                              lineHeight: 1.66,
                              fontWeight: 400,
                              fontFamily: "Roboto",
                              marginTop: -1,
                              marginRight: 1,
                              color: "rgba(0,0,0,0.6)",
                              textAlign: "right",
                            }}
                          >
                            12 AM
                          </Typography>
                        </Box>
                      </TimeLabel>
                    </TimeSlotHeader>
                  </Box>

                  {/* Availability Scheduler */}
                  <Grid
                    container
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    {/* Day headers */}
                    <Grid
                      container
                      sx={{
                        position: "sticky",
                        top: 0,
                        height: `${dayHeaderHeight}px`,
                        minHeight: `${dayHeaderHeight}px`,
                        backgroundColor: "rgba(255,255,255)",
                        zIndex: 1000,
                      }}
                    >
                      {currentDate &&
                        Array.from({ length: 7 }).map((_, index) => {
                          const day = addDays(startingDay, index);
                          return (
                            <MemoizedDayColumnHeader
                              item
                              xs
                              key={index}
                              day={day}
                            >
                              <DayHeader>
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    lineHeight: 1.2,
                                    fontWeight: isSameDay(day, new Date())
                                      ? 600
                                      : 400,
                                    fontFamily: "Roboto",
                                    margin: 0,
                                    color: isSameDay(day, new Date())
                                      ? "#2F4D8B"
                                      : "rgba(0,0,0,0.6)",
                                  }}
                                >
                                  {format(day, "EEE")}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "1.8rem",
                                    lineHeight: 1.2,
                                    fontWeight: isSameDay(day, new Date())
                                      ? 600
                                      : 400,
                                    fontFamily: "Roboto",
                                    color: isSameDay(day, new Date())
                                      ? "#2F4D8B"
                                      : "rgba(0,0,0,0.6)",
                                  }}
                                >
                                  {format(day, "d")}
                                </Typography>
                              </DayHeader>
                            </MemoizedDayColumnHeader>
                          );
                        })}
                    </Grid>
                    <Grid
                      container
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      {/* Time slots */}
                      {Array.from({ length: 7 }).map((_, dayIndex) => {
                        const day = addDays(startingDay, dayIndex);

                        return (
                          <MemoizedDayColumn
                            item
                            xs
                            key={dayIndex}
                            day={day}
                            timeSlotHeight={timeSlotHeight}
                          >
                            {hours.map((hour, hourIndex) => {
                              return <Availability
                                key={`worker-availability-${dayIndex}-${hourIndex}`}
                                data={getDataByHourAndDay(dayIndex, hourIndex)}
                                timeSlotHeight={timeSlotHeight}
                              />;
                            })}
                          </MemoizedDayColumn>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </>
        )}
    </Box>
  );
}

export default AvailabilityMap;
