import { Layers } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import isPast from "date-fns/isPast";
import React, { useMemo, useState } from "react";

import {gutterWidth, minTimeSlotHeight, shiftSpacing} from "./constants";
import { TimeSlot } from "./styled-components";

const TimeSlotContainer = ({
  openCreateNewShiftDialog,
  canCreateOrEditShift,
  dayIndex,
  todayPosition,
  hourIndex,
  day,
  timeSlotHeight,
  allPassingShifts,
  openLayerPopover,
  ...props
}: {
  openCreateNewShiftDialog: any;
  canCreateOrEditShift: boolean;
  dayIndex: number;
  todayPosition: number;
  hourIndex: number;
  day: Date;
  timeSlotHeight: number;
  allPassingShifts: any;
  openLayerPopover: any;
} & React.ComponentProps<typeof Grid>) => {
  // One hour string based on hourIndex (0-23) like "4:00 AM - 5:00 AM"
  const hourString = useMemo(() => {
    const startHour = hourIndex;
    const endHour = hourIndex + 1;
    // Change military time to standard time
    let startHourString = startHour % 12 === 0 ? "12" : `${startHour % 12}`;
    let endHourString = endHour % 12 === 0 ? "12" : `${endHour % 12}`;
    // Add AM and PM
    if (startHour < 12) {
      startHourString = startHourString + " AM";
    } else {
      startHourString = startHourString + " PM";
    }
    if (endHour < 12) {
      endHourString = endHourString + " AM";
    } else {
      endHourString = endHourString + " PM";
    }
    const shiftsLength = allPassingShifts
      ? Array.isArray(allPassingShifts)
        ? allPassingShifts.length
        : 0
      : 0;
    return `${format(
      day,
      "EEE, dd MMM"
    )} - ${startHourString} to ${endHourString} (${shiftsLength} Shifts)`;
  }, [hourIndex, day, allPassingShifts]);

  const [isHovered, setIsHovered] = useState(false);

  const isInPast = useMemo(() => isPast(day), [day]);
  const isToday = useMemo(
    () => todayPosition === dayIndex,
    [todayPosition, dayIndex]
  );
  const isPastHour = useMemo(
    () => isInPast && hourIndex - 1 < new Date().getHours(),
    [isInPast, hourIndex]
  );
  const dateWithHour = useMemo(
    () => new Date(day).setHours(hourIndex),
    [day, hourIndex]
  );

  const handleClick = () => {
    if (canCreateOrEditShift) {
      openCreateNewShiftDialog(new Date(dateWithHour));
    }
  };

  let isPropsEmpty = true;

  // Check if props is empty or not by evaluating props contain children or not
  if (Array.isArray(props.children) ? props.children.length > 0 : false) {
    isPropsEmpty = false;
  }

  // Check if allPassingShifts is empty or not by evaluating allPassingShifts contain children or not
  isPropsEmpty = Array.isArray(allPassingShifts)
    ? allPassingShifts.length === 0
    : isPropsEmpty;

  const canShowAddIcon =
    isHovered && !(isInPast && !isToday) && !(isToday && isPastHour);
  let canShowLayerIcon =
    Array.isArray(allPassingShifts) && allPassingShifts.length > 4;

  // Display layer icon if shifts are eclipsing each other under 10min
  if (!canShowLayerIcon) {
    if (Array.isArray(allPassingShifts) && allPassingShifts.length >= 2) {
      const minMargin = shiftSpacing * 3;
      const minWidth = shiftSpacing * 2;
      const minDifferenceInMinutes = 9; // if shifts difference is 9 min or less then display layer icon
      allPassingShifts.forEach((shift: any, index: number) => {
        if (index + 1 < allPassingShifts.length) {
          const currentShift = shift;
          const nextShift = allPassingShifts[index + 1];
          const currentShiftStartTime = new Date(currentShift.startDateTime);
          const nextShiftStartTime = new Date(nextShift.startDateTime);
          const differenceInMinutes =
            (nextShiftStartTime.getTime() - currentShiftStartTime.getTime()) /
            60000;
          if (
            currentShift.changedMarginOfShift === minMargin &&
            currentShift.shiftWidthPercent === minWidth &&
            nextShift.changedMarginOfShift === minMargin &&
            nextShift.shiftWidthPercent === minWidth &&
            differenceInMinutes <= minDifferenceInMinutes
          ) {
            canShowLayerIcon = true;
          }
        }
      });
    }
  }

  if ((isInPast && !isToday) || (isToday && isPastHour) || !isPropsEmpty) {
    return (
      <TimeSlot
        {...props}
        height={timeSlotHeight}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {props.children}
        {!isPropsEmpty && (
          <Box
            sx={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: `${gutterWidth}px`,
              height: `${timeSlotHeight}px`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {canShowAddIcon && (
              <Tooltip title={"Add Shift"} placement="top-start">
                <IconButton
                  aria-label="add"
                  size="small"
                  sx={{
                    width: `${gutterWidth}px`,
                    height: `${minTimeSlotHeight / 2}px`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                >
                  <AddIcon
                    sx={{
                      fontSize: `${gutterWidth / 1.5}px`,
                      color: "#2F4D80",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {/* Spacer */}
            {canShowLayerIcon && !canShowAddIcon && (
              <Box
                sx={{
                  width: `${gutterWidth}px`,
                  height: `${minTimeSlotHeight / 2}px`,
                }}
              />
            )}
            {canShowLayerIcon && (
              <Tooltip title={"View Shifts"} placement="bottom-start">
                <IconButton
                  aria-label="view-shift"
                  size="small"
                  sx={{
                    width: `${gutterWidth}px`,
                    height: `${minTimeSlotHeight / 2}px`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => openLayerPopover(e, hourString, allPassingShifts)}
                >
                  <Layers
                    sx={{
                      fontSize: `${gutterWidth / 1.5}px`,
                      color: "#2F4D80",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </TimeSlot>
    );
  } else {
    return (
      <TimeSlot
        {...props}
        height={timeSlotHeight}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              cursor: "pointer",
              backgroundColor: isHovered ? "rgba(0,0,0,0.01)" : "transparent",
              flexDirection: "row",
            }}
          >
            <AddIcon
              sx={{
                fontSize: "20px",
                textTransform: "uppercase",
                fontFamily: "Roboto",
                fontWeight: 500,
                color: "#2F4D80",
              }}
            />
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "14px",
                textTransform: "uppercase",
                fontFamily: "Roboto",
                fontWeight: 500,
                color: "#2F4D80",
              }}
            >
              Shift
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </TimeSlot>
    );
  }
};

export default React.memo(TimeSlotContainer);
