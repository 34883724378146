import {addDays, isSameDay} from "date-fns";

/**
 * Returns the numeric representation of a day (0 = Sunday, 1 = Monday, ..., 6 = Saturday).
 * Defaults to Monday if the input is invalid.
 *
 * @param dayName - Name of the day (e.g., "Monday", "Tuesday").
 * @returns Day number (0 to 6).
 */
export const getDayNumber = (dayName: string): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
    const days: Record<string, 0 | 1 | 2 | 3 | 4 | 5 | 6> = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 0,
    };
    return days[dayName.toLowerCase()] || 1; // Default to Monday
};

/**
 * Calculates the position of a time indicator within a day based on the current time.
 *
 * @param time - The current time as a Date object.
 * @param timeSlotHeight - The time slot grid height
 * @returns The position of the indicator as a string with "px" units.
 */
export const calculateIndicatorPosition = (time: Date, timeSlotHeight: number) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const totalMinutes = hours * 60 + minutes;
    return `${(totalMinutes / (24 * 60)) * (24 * timeSlotHeight)}px`; // Calculate position in pixels
};

/**
 * Calculates the position of today's date relative to a given starting day.
 *
 * @param startingDay - The starting day of the week.
 * @returns The position of today in the week (0 to 6), or -1 if not found.
 */
export const calculateTodayPosition = (startingDay: Date): number => {
    const today = new Date();

    for (let i = 0; i < 7; i++) {
        const currentDay = addDays(startingDay, i);
        if (isSameDay(today, currentDay)) {
            return i;
        }
    }

    return -1; // Default if today is not in the week
};

export const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i % 12 === 0 ? 12 : i % 12; // Convert 24-hour format to 12-hour format
    const period = i < 12 ? "AM" : "PM";     // Determine AM or PM
    return `${hour} ${period}`;
});

export const getLocalTimeZone = (): string => {
    const offset = new Date().getTimezoneOffset();
    const hours = Math.abs(offset) / 60;
    const minutes = Math.abs(offset) % 60;
    const sign = offset > 0 ? "-" : "+";

    return `GMT ${sign}${Math.floor(hours)}:${minutes.toString().padStart(2, "0")}`;
};