import { Box, Grid, Skeleton } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import React, { ComponentType, useMemo } from "react";
import {
  FixedSizeList as _FixedSizeList,
} from "react-window";
import {
    FixedSizeListProps
} from "react-window"; // Shifted to resolve ESLint sort error

import TooltipContent from "../../components/scheduler/ui/tooltip/tooltip-content";
import { dayHeaderHeight, gutterWidth } from "./grid-scheduler/constants";
import useGridSchedulerController from "./grid-scheduler/controllers/use-grid-scheduler-controller";
import MemoizedCurrentTimeIndicator from "./grid-scheduler/memoized-current-time-indicator";
import MemoizedDayColumn from "./grid-scheduler/memoized-day-column";
import MemoizedDayColumnHeader from "./grid-scheduler/memoized-day-column-header";
import { ShiftContainer } from "./grid-scheduler/shift-container";
import {
  DayHeader,
  TimeLabel,
  TimeSlot,
  TimeSlotHeader,
} from "./grid-scheduler/styled-components";
import TimeSlotContainer from "./grid-scheduler/time-slot-container";
import { getDayNumber, getLocalTimeZone, hours } from "./grid-scheduler/utils";

const FixedSizeList =
  _FixedSizeList as unknown as ComponentType<FixedSizeListProps>; // To resolve FixedSizeList type error

export default function MyScheduler({
  openPublishDraftShiftDialog,
  openShiftCancelDialog,
  openDeleteDraftShiftDialog,
  openCreateNewShiftDialog,
  openShiftDetailsDialog,
  setShiftIdForShiftDetailsDialog,
  openShiftAttendanceDialog,
  changeWeekAsyncStatus,
  changeDayAsyncStatus,
  height,
  filterAccordionHeight,
  isLoading,
}: any) {
  const [
    {
      anchorEl,
      selectedShift,
      currentTime,
      timeSlotHeight,
      weekStartDay,
      startingDay,
      currentDate,
      isWeekView,
      todayPosition,
      pastWeekShifts,
      processedShifts,
      shifts,
      userRoles,
      canCreateOrEditShift,
      visible,
      isLayerPopoverOpen,
      layerEl,
      listRef,
      hourString,
      selectedShiftIndex,
      hourPassingShifts,
    },
    {
      toggleVisibility,
      setVisible,
      generateSkeletonVisibility,
      openShiftPopover,
      setSelectedShiftIndex,
      closeLayerPopover,
      openLayerPopover,
    },
  ] = useGridSchedulerController(height);

  const showSkeleton = useMemo(() => {
    return (
      changeWeekAsyncStatus === "pending" ||
      !currentDate ||
      changeDayAsyncStatus === "pending" ||
      isLoading
    );
  }, [changeDayAsyncStatus, changeWeekAsyncStatus, currentDate, isLoading]);

  // Memoized Skeleton Header loader for performance improvement
  const memoizedDayHeadersWithSkeleton = useMemo(() => {
    return Array.from({ length: isWeekView ? 7 : 1 }).map((_, index) => {
      return (
        <MemoizedDayColumnHeader
          item
          xs
          key={`day-header-skeleton-${index}`}
          day={addDays(new Date(), 1)}
        >
          <DayHeader>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton
                variant="rounded"
                width={30}
                height={14}
                sx={{
                  marginBottom: 0.5,
                  backgroundColor: "#F5F5F5",
                }}
              />
              <Skeleton
                variant="rounded"
                width={40}
                height={22}
                sx={{ backgroundColor: "#F5F5F5", marginBottom: 1 }}
              />
            </Box>
          </DayHeader>
        </MemoizedDayColumnHeader>
      );
    });
  }, [isWeekView]);

  // Memoized Skeleton Time slots loader for performance improvement
  const memoizedTimeSlotsWithSkeleton = useMemo(() => {
    return Array.from({ length: isWeekView ? 7 : 1 }).map((_, dayIndex) => {
      const day = isWeekView ? addDays(new Date(), 1) : new Date();
      const skeletonVisibility = generateSkeletonVisibility(
        hours.length,
        dayIndex
      );
      return (
        <MemoizedDayColumn
          item
          xs
          key={dayIndex}
          day={day}
          timeSlotHeight={timeSlotHeight}
        >
          {hours.map((_, hourIndex) => (
            <TimeSlot height={timeSlotHeight} key={hourIndex}>
              {skeletonVisibility[hourIndex] ? (
                <Skeleton
                  key={`shift-skeleton--${dayIndex}-${hourIndex}`}
                  variant="rounded"
                  width="100%"
                  animation="wave"
                  height={timeSlotHeight * 2}
                  sx={{
                    backgroundColor: "#F5F5F5",
                    marginLeft: "12px",
                    marginRight: "24px",
                  }}
                />
              ) : null}
            </TimeSlot>
          ))}
        </MemoizedDayColumn>
      );
    });
  }, [generateSkeletonVisibility, isWeekView, timeSlotHeight]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: `${filterAccordionHeight}px`,
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "flex-start",
          }}
        >
          {/* Time Labels and Indicators */}
          <Box
            sx={{
              minWidth: "80px",
              maxWidth: "80px",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                height: `${dayHeaderHeight}px`,
                minHeight: `${dayHeaderHeight}px`,
                backgroundColor: "rgba(255,255,255)",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                zIndex: 1001,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  lineHeight: 1.66,
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  color: "rgba(0,0,0,0.3)",
                  textAlign: "right",
                }}
              >
                {getLocalTimeZone()}
              </Typography>
            </Box>
            <TimeSlotHeader
              height={timeSlotHeight}
              key={"time-slot-header-first"}
            />
            {hours.map((hour, index) => (
              <TimeSlotHeader
                height={timeSlotHeight}
                key={`time-slot-header-${index}`}
              >
                <TimeLabel>
                  <Box
                    sx={{
                      width: "100%",
                      marginRight: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        lineHeight: 1.66,
                        fontWeight: 400,
                        fontFamily: "Roboto",
                        marginTop: -1,
                        marginRight: 1,
                        color: "rgba(0,0,0,0.6)",
                        textAlign: "right",
                      }}
                    >
                      {hour}
                    </Typography>
                  </Box>
                </TimeLabel>
              </TimeSlotHeader>
            ))}
            <TimeSlotHeader
              height={timeSlotHeight}
              key={"time-slot-header-last"}
            >
              <TimeLabel>
                <Box
                  sx={{
                    width: "100%",
                    marginRight: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      lineHeight: 1.66,
                      fontWeight: 400,
                      fontFamily: "Roboto",
                      marginTop: -1,
                      marginRight: 1,
                      color: "rgba(0,0,0,0.6)",
                      textAlign: "right",
                    }}
                  >
                    12 AM
                  </Typography>
                </Box>
              </TimeLabel>
            </TimeSlotHeader>
          </Box>

          {/* Day Columns */}
          <Grid
            container
            sx={{
              flexGrow: 1,
            }}
          >
            {/* Day headers */}
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                height: `${dayHeaderHeight}px`,
                minHeight: `${dayHeaderHeight}px`,
                backgroundColor: "rgba(255,255,255)",
                zIndex: 1000,
              }}
            >
              {showSkeleton
                ? memoizedDayHeadersWithSkeleton
                : currentDate &&
                  Array.from({ length: isWeekView ? 7 : 1 }).map((_, index) => {
                    const day = isWeekView
                      ? addDays(startingDay, index)
                      : new Date(currentDate);
                    return (
                      <MemoizedDayColumnHeader item xs key={index} day={day}>
                        <DayHeader>
                          <Typography
                            sx={{
                              fontSize: "0.75rem",
                              lineHeight: 1.2,
                              fontWeight: isSameDay(day, new Date())
                                ? 600
                                : 400,
                              fontFamily: "Roboto",
                              margin: 0,
                              color: isSameDay(day, new Date())
                                ? "#2F4D8B"
                                : "rgba(0,0,0,0.6)",
                            }}
                          >
                            {format(day, "EEE")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "1.8rem",
                              lineHeight: 1.2,
                              fontWeight: isSameDay(day, new Date())
                                ? 600
                                : 400,
                              fontFamily: "Roboto",
                              color: isSameDay(day, new Date())
                                ? "#2F4D8B"
                                : "rgba(0,0,0,0.6)",
                            }}
                          >
                            {format(day, "d")}
                          </Typography>
                        </DayHeader>
                      </MemoizedDayColumnHeader>
                    );
                  })}
            </Grid>
            <Grid
              container
              sx={{
                overflow: "hidden",
              }}
            >
              {/* Time slots */}
              {showSkeleton
                ? memoizedTimeSlotsWithSkeleton
                : Array.from({ length: isWeekView ? 7 : 1 }).map((_, index) => {
                    const day = isWeekView
                      ? addDays(startingDay, index)
                      : currentDate
                      ? new Date(currentDate)
                      : new Date();
                    const dayIndex = isWeekView
                      ? index
                      : (day.getDay() + 7 - getDayNumber(weekStartDay)) % 7;
                    return (
                      <MemoizedDayColumn
                        item
                        xs
                        key={dayIndex}
                        day={day}
                        timeSlotHeight={timeSlotHeight}
                      >
                        {dayIndex === todayPosition && (
                          <MemoizedCurrentTimeIndicator
                            currentTime={currentTime}
                          />
                        )}
                        {hours.map((hour, hourIndex) => {
                          // all shifts passing, ending or originating on this hour
                          const allPassingShifts = processedShifts.filter(
                            (shift: any) => {
                              const startDate = new Date(
                                shift.startDateTime
                              ).getTime();
                              const endDate = new Date(
                                shift.endDateTime
                              ).getTime();
                              const zoneStartDate = new Date(day).setHours(
                                hourIndex, 0, 0, 0
                              );
                              const zoneEndDate = new Date(day).setHours(
                                hourIndex + 1, 0, 0, 0
                              );
                              return (
                                (startDate >= zoneStartDate &&
                                  startDate < zoneEndDate) ||
                                (endDate > zoneStartDate &&
                                  endDate <= zoneEndDate) ||
                                (startDate <= zoneStartDate &&
                                  endDate >= zoneEndDate)
                              );
                            }
                          );

                          let validShifts = processedShifts.map(
                            (shift: any, shiftIndex: number) => {
                              const isPastWeekShifts = pastWeekShifts.some(
                                (s: { id: any }) => s.id === shift.id
                              );
                              if (
                                (!isPastWeekShifts &&
                                  shift.shiftHourIndex === hourIndex &&
                                  shift.shiftDayIndex === dayIndex) ||
                                (shift.shiftEndHourIndex !== -1 &&
                                  shift.shiftEndHourIndex === hourIndex &&
                                  shift.shiftNextDayIndex === dayIndex)
                              ) {
                                return (
                                  <Box
                                    key={`shift-${shiftIndex}`}
                                    sx={{
                                      position: "absolute",
                                      top:
                                        shift.shiftEndHourIndex !== -1 &&
                                        shift.shiftEndHourIndex === hourIndex &&
                                        shift.shiftNextDayIndex === dayIndex
                                          ? "auto"
                                          : `${shift.shiftTop}%`,
                                      bottom:
                                        shift.shiftEndHourIndex !== -1 &&
                                        shift.shiftEndHourIndex === hourIndex &&
                                        shift.shiftNextDayIndex === dayIndex
                                          ? `${shift.shiftBottom}%`
                                          : "auto",
                                      height: `${shift.shiftHeight}px`,
                                      left:
                                        shift.shiftWidthPercent === 50 &&
                                        shift.changedMarginOfShift === 50
                                          ? `calc(${
                                              shift.shiftIndexInOverlapping > 0
                                                ? shift.changedMarginOfShift
                                                : 0
                                            }% - ${gutterWidth / 4}px)`
                                          : `calc(${
                                              shift.shiftIndexInOverlapping > 0
                                                ? shift.changedMarginOfShift
                                                : 0
                                            }% + ${gutterWidth / 2}px)`,
                                      width:
                                        shift.shiftWidthPercent === 50
                                          ? `calc(${
                                              shift.shiftWidthPercent
                                            }% - ${(gutterWidth * 3) / 4}px)`
                                          : `calc(${
                                              shift.shiftWidthPercent
                                            }% - ${(gutterWidth * 3) / 2}px)`,
                                      cursor: "pointer",
                                      zIndex: 26 + shiftIndex, // To ensure it is above time slots
                                    }}
                                    onClick={(e) => {
                                      anchorEl.current = e.currentTarget;
                                      selectedShift.current = shift;
                                      setVisible(true);
                                    }}
                                  >
                                    <ShiftContainer
                                      data={shift}
                                      infoAtBottom={
                                        shift.shiftEndHourIndex !== -1 &&
                                        shift.shiftNextDayIndex === dayIndex
                                      }
                                    />
                                  </Box>
                                );
                              }
                              return null;
                            }
                          );

                          validShifts = validShifts.filter(
                            (shift: any) => shift !== null
                          );
                          return (
                            <TimeSlotContainer
                              openCreateNewShiftDialog={
                                openCreateNewShiftDialog
                              }
                              canCreateOrEditShift={
                                !userRoles.includes("ReadOnly") &&
                                canCreateOrEditShift
                              }
                              dayIndex={dayIndex}
                              todayPosition={todayPosition}
                              hourIndex={hourIndex}
                              day={day}
                              timeSlotHeight={timeSlotHeight}
                              allPassingShifts={allPassingShifts}
                              openLayerPopover={openLayerPopover}
                              key={hourIndex}
                            >
                              {validShifts ? validShifts : null}
                            </TimeSlotContainer>
                          );
                        })}
                      </MemoizedDayColumn>
                    );
                  })}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {anchorEl.current && (
        <Popover
          open={visible}
          anchorEl={anchorEl.current}
          onClose={() => {
            anchorEl.current = null;
            selectedShift.current = undefined;
            setVisible(false);
            setSelectedShiftIndex(null);
          }}
          anchorOrigin={layerEl ? {
                vertical: "center",
                horizontal: "center",
          } : {
              vertical: "top",
              horizontal: "center",
          }}
          transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
          }}
        >
          <TooltipContent
            shiftData={selectedShift.current}
            setShiftIdForShiftDetailsDialog={setShiftIdForShiftDetailsDialog}
            openPublishDraftShiftDialog={openPublishDraftShiftDialog}
            openShiftCancelDialog={openShiftCancelDialog}
            openShiftDetailsDialog={openShiftDetailsDialog}
            openDeleteDraftShiftDialog={openDeleteDraftShiftDialog}
            openShiftAttendanceDialog={openShiftAttendanceDialog}
            toggleVisibility={toggleVisibility}
            visible={visible}
          />
        </Popover>
      )}
      {layerEl && (
        <Popover
          open={isLayerPopoverOpen}
          anchorEl={layerEl}
          onClose={closeLayerPopover}
          anchorOrigin={{
              vertical: "top",
              horizontal: "center",
          }}
          transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
          }}
        >
          <Box
            ref={listRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "280px",
              height: "480px",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#F5F5F5",
                padding: "6px 12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  color: "#000",
                }}
              >
                {hourString}
              </Typography>
            </Box>
            <FixedSizeList
              height={480}
              width={280}
              itemSize={136}
              itemCount={hourPassingShifts.length}
            >
              {({ index, style }) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    padding: "6px 24px",
                    backgroundColor:
                      index === selectedShiftIndex ? "#2F4D8B14" : "#fff",
                    ...style,
                  }}
                  onClick={() => {
                    setSelectedShiftIndex(index);
                    openShiftPopover(listRef.current, hourPassingShifts[index]);
                  }}
                >
                  <ShiftContainer
                    data={hourPassingShifts[index]}
                    isLayer={true}
                  />
                </Box>
              )}
            </FixedSizeList>
          </Box>
        </Popover>
      )}
    </>
  );
}
