import { useSelector } from "react-redux";

const useWorkersData = () => {
	const allShiftSchedules = useSelector(
		(state: any) => state.manageWorkers.getAllShiftTypes.data
	);

	const deleteWorkerSkillStatus = useSelector(
		(state: any) => state.manageWorkers.deleteWorkerSkills.status
	);

	const deleteWorkerSkillError = useSelector(
		(state: any) => state.manageWorkers.deleteWorkerSkills.errorMessage
	);

	const allSkillLevels = useSelector(
		(state: any) => state.manageWorkers.skillLevels
	);

	const allJobsObj = useSelector(
		(state: any) => state.manageWorkers.allJobRoles
	);

	const workerJobs = useSelector(
		(state: any) => state.manageWorkers.workerProfile.profile?.jobs
	);

	const workerOtherSkills = useSelector(
		(state: any) => state.manageWorkers.workerProfile.profile?.otherSkills
	);

	const workerId = useSelector(
		(state: any) => state.manageWorkers.workerProfile.profile?.workerId
	);

	const workerProfile = useSelector(
		(state: any) => state.manageWorkers.workerProfile.profile
	);

	const allSkills = useSelector((state: any) => state.manageWorkers.allSkills);
	const obsoleteSkills = useSelector(
		(state: any) => state.manageJobs.obsoleteSkills
	);

	const addWorkerSkillStatus = useSelector(
		(state: any) => state.manageWorkers.addWorkerSkills.status
	);

	const addWorkerSkillError = useSelector(
		(state: any) => state.manageWorkers.addWorkerSkills.errorMessage
	);

	const editWorkerSkillStatus = useSelector(
		(state: any) => state.manageWorkers.editWorkerSkill.status
	);

	const editWorkerSkillError = useSelector(
		(state: any) => state.manageWorkers.editWorkerSkill.errorMessage
	);

	const isEditWorkerSkillDialogOpen = useSelector(
		(state: any) => state.manageWorkers.editWorkerSkillDialog.isOpen
	);

	const allSkillsObj = useSelector(
		(state: any) => state.manageJobs.allSkillsObj
	);

	const certificates = useSelector(
		(state: any) => state.skills.masterData.certificates
	);

	const obsoleteCertificates = useSelector(
		(state: any) => state.skills.masterData.obsoleteCertificates
	);

	const deleteCertificationStatus = useSelector(
		(state: any) => state.manageWorkers.deleteWorkerCertifications.status
	);

	const deleteCertificationError = useSelector(
		(state: any) => state.manageWorkers.deleteWorkerCertifications.errorMessage
	);

	const allCertifications = useSelector(
		(state: any) => state.skills.masterData.certificates
	);

	const addWorkerCertificationStatus = useSelector(
		(state: any) => state.manageWorkers.addWorkerCertifications.status
	);

	const addWorkerCertificationError = useSelector(
		(state: any) => state.manageWorkers.addWorkerCertifications.errorMessage
	);

	const editWorkerStatus = useSelector(
		(state: any) => state.manageWorkers.editWorker.status
	);
	const isEditWorkerCertificationDialogOpen = useSelector(
		(state: any) => state.manageWorkers.editWorkerCertificationDialog.isOpen
	);

	const editWorkerCertificationStatus = useSelector(
		(state: any) => state.manageWorkers.editWorkerCertification.status
	);

	const postUploadFilesStatus = useSelector(
		(state: any) => state.manageWorkers.postUploadFiles.status
	);

	const postDeleteFilesStatus = useSelector(
		(state: any) => state.manageWorkers.postDeleteFiles.status
	);

	const failedFileUploadCount = useSelector(
		(state: any) => state.manageWorkers.postUploadFiles.failedUploads
	);

	const failedFileDeleteCount = useSelector(
		(state: any) => state.manageWorkers.postDeleteFiles.failedDeletes
	);

	const downloadProgress = useSelector(
		(state: any) => state.manageWorkers.downloadWorkerCertification.progress
	);

	const allJobRoles = useSelector((state: any) => state.manageJobs?.allJobs);
	const fetchAllJobRolesStatus = useSelector(
		(state: any) => state.supervisor.fetchAllJobRoles.status
	);

	const fetchAllJobRolesErrorMessage = useSelector(
		(state: any) => state.supervisor.fetchAllJobRoles.errorMessage
	);

	// Worker being edited
	const editDialogWorkerId = useSelector(
		(state: any) => state.manageWorkers.editWorkerDialog.workerId
	);

	const currentEditStep = useSelector(
		(state: any) => state.manageWorkers.editWorkerDialog.currentStep
	);

	const allWorkersData = useSelector(
		(state: any) => state.manageWorkers.allWorkers
	);

	const createNewWorkerStatus = useSelector(
		(state: any) => state.manageWorkers.createNewWorker.status
	);

	const createNewWorkerErrorMessage = useSelector(
		(state: any) => state.manageWorkers.createNewWorker.errorMessage
	);

	const editWorkerErrorMessage = useSelector(
		(state: any) => state.manageWorkers.editWorker.errorMessage
	);

	const updateWorkerJobExpiryStatus = useSelector(
		(state: any) => state.manageWorkers.updateWorkerJobExpiry.status
	);

	const allSupervisors = useSelector(
		(state: any) => state.supervisor.masterData.allManagers
	);

	const locationShiftPatterns = useSelector(
		(state: any) => state.manageWorkers.locationShiftPatterns
	);

	const getLocationShiftPatternStatus = useSelector(
		(state: any) => state.manageWorkers.getLocationShiftPatterns.status
	);

	const fetchWorkerProfileStatus = useSelector(
		(state: any) => state.manageWorkers.workerProfile.status
	);

	const isCertificationEnabled = useSelector(
		(state: any) => state.user.userData.certificationsEnabled
	);

	const isEmployeeIdUniqueStatus = useSelector(
		(state: any) => state.manageWorkers.isEmployeeIdUnique.status
	);

	const isEmployeeIdUniqueData = useSelector(
		(state: any) => state.manageWorkers.isEmployeeIdUnique.data
	);

	const allSkillsWithPriority = useSelector(
		(state: any) => state.skills.masterData.skills
	);

	const skillExpiryEnabled = useSelector(
		(state: any) => state.user.userData.skillExpiryEnabled
	);

	const skillPriorityEnabled = useSelector(
		(state: any) => state?.user?.userData?.skillPriorityEnabled
	);

	const workerTypes = useSelector(
		(state: any) => state.manageWorkers.workerTypes
	);

	const workerSubTypes = useSelector(
		(state: any) => state.manageWorkers.fetchWorkerSubType.data
	);

	const getWorkerSubTypeStatus = useSelector(
		(state: any) => state.manageWorkers.fetchWorkerSubType.status
	);

	const isWorkerSubTypeEnabled = useSelector(
		(state: any) => state.user.userData.workerSubTypeEnabled
	);

	return {
		obsoleteCertificates,
		obsoleteSkills,
		editWorkerStatus,
		workerProfile,
		addWorkerSkillStatus,
		addWorkerSkillError,
		allShiftSchedules,
		deleteWorkerSkillStatus,
		deleteWorkerSkillError,
		allSkillLevels,
		allJobsObj,
		allSkillsObj,
		workerJobs,
		workerOtherSkills,
		allSkills,
		skillExpiryEnabled,
		allSkillsWithPriority,
		skillPriorityEnabled,
		certificates,
		workerId,
		editWorkerSkillStatus,
		editWorkerSkillError,
		isEditWorkerSkillDialogOpen,
		deleteCertificationStatus,
		deleteCertificationError,
		allCertifications,
		addWorkerCertificationStatus,
		addWorkerCertificationError,
		isEditWorkerCertificationDialogOpen,
		editWorkerCertificationStatus,
		postUploadFilesStatus,
		postDeleteFilesStatus,
		failedFileUploadCount,
		failedFileDeleteCount,
		downloadProgress,
		isCertificationEnabled,
		fetchWorkerProfileStatus,
		locationShiftPatterns,
		getLocationShiftPatternStatus,
		allSupervisors,
		allJobRoles,
		fetchAllJobRolesStatus,
		fetchAllJobRolesErrorMessage,
		editDialogWorkerId,
		allWorkersData,
		createNewWorkerStatus,
		currentEditStep,
		createNewWorkerErrorMessage,
		editWorkerErrorMessage,
		updateWorkerJobExpiryStatus,
		isEmployeeIdUniqueData,
		isEmployeeIdUniqueStatus,
		workerSubTypes,
		getWorkerSubTypeStatus,
		workerTypes,
		isWorkerSubTypeEnabled,
	};
};

export default useWorkersData;
