import { subMinutes } from "date-fns";
import addDays from "date-fns/addDays";
import isSameDay from "date-fns/isSameDay";
import startOfWeek from "date-fns/startOfWeek";
import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { dayHeaderHeight, minTimeSlotHeight, shiftSpacing } from "../constants";
import { getDayNumber } from "../utils";
import useCurrentTime from "./use-current-time";

const useGridSchedulerController = (height: number): any => {
  const anchorEl = useRef<any>(null);
  const selectedShift = useRef<any>(undefined);
  const weekStartDay = useSelector(
    (state: any) => state.user?.userData?.weekStartDay
  );

  const currentDate =
    useSelector((state: any) => state.supervisor?.schedulerData?.currentDate) ??
    new Date().toISOString();
  const userRoles = useSelector((state: any) => state.user.userData.userRoles);
  const fetchFilterAndShiftData = useSelector(
    (state: any) => state.supervisor.fetchFilterAndShiftData.status
  );
  const canCreateOrEditShift = fetchFilterAndShiftData === "fulfilled";

  const startingDay = useMemo(
    () =>
      startOfWeek(new Date(currentDate), {
        weekStartsOn: getDayNumber(weekStartDay),
      }),
    [weekStartDay, currentDate]
  );

  const endingDay = useMemo(() => addDays(startingDay, 7), [startingDay]);

  const isWeekView = useSelector((state: any) => state.supervisor.weekView);

  const [visible, setVisible] = useState(false);

  const timeSlotHeight: number = useMemo(() => {
    const minHeight = minTimeSlotHeight;
    // overall screen height without filter and day header divided by number of time slots in a day
    const returnValue = (height - 2 * dayHeaderHeight) / 26;
    return returnValue < minHeight ? minHeight : returnValue;
  }, [height]);

  const toggleVisibility = (val: any) => {
    if (!val) {
      anchorEl.current = null;
      selectedShift.current = undefined;
    }
    setVisible(val);
  };

  const openShiftPopover = (ref: any, shift: any) => {
    selectedShift.current = shift;
    anchorEl.current = ref;
    setVisible(true);
  };

  const currentTime = useCurrentTime(timeSlotHeight);

  const allShifts = useSelector(
    (state: any) => state.supervisor?.schedulerData?.filteredShiftsData
  );

  const overlappingDaysShifts = useMemo(() => {
    return allShifts.filter((shift: any) => {
      const startDate = new Date(shift.startDateTime);
      const endDate = new Date(subMinutes(new Date(shift.endDateTime), 1));
      return (
        (endDate > startingDay &&
          startDate < startingDay &&
          startDate.getDay() !== endDate.getDay()) ||
        (startDate > startingDay &&
          endDate < endingDay &&
          startDate.getDay() !== endDate.getDay())
      );
    });
  }, [allShifts, startingDay, endingDay]);

  // Past week shift in overlapping shifts
  const pastWeekShifts = useMemo(() => {
    return overlappingDaysShifts.filter((shift: any) => {
      const startDate = new Date(shift.startDateTime);
      const endDate = new Date(shift.endDateTime);
      return startDate < startingDay && endDate > startingDay;
    });
  }, [overlappingDaysShifts, startingDay]);

  // Filter all shifts based on start and end date
  const filteredShifts = useMemo(() => {
    return allShifts.filter((shift: any) => {
      const startDate = new Date(shift.startDateTime);
      return startDate >= startingDay && startDate <= endingDay;
    });
  }, [allShifts, startingDay, endingDay]);

  const uniqueShifts = useMemo(() => {
    return Array.from(new Set(filteredShifts.map((a: any) => a.id))).map(
      (id) => {
        return filteredShifts.find((a: any) => a.id === id);
      }
    );
  }, [filteredShifts]);

  // sort shifts by startDateTime
  const sortedShifts = useMemo(() => {
    return uniqueShifts.sort((a: any, b: any) => {
      const aStart = new Date(a.startDateTime);
      const bStart = new Date(b.startDateTime);
      return aStart.getTime() - bStart.getTime();
    });
  }, [uniqueShifts]);

  const sortedPassedWeekShifts = useMemo(() => {
    return pastWeekShifts.sort((a: any, b: any) => {
      const aStart = new Date(a.startDateTime);
      const bStart = new Date(b.startDateTime);
      return aStart.getTime() - bStart.getTime();
    });
  }, [pastWeekShifts]);

  // Filter shifts from weekStartDay to weekStartDay + 7
  // Do not add later date shifts if weekStartDay appeared twice in uniqueShifts
  const shifts = useMemo(() => {
    const datesAdded: any[] = [];
    const clippedShifts = sortedShifts.filter((shift: any) => {
      const shiftDate = new Date(shift.startDateTime);
      const indexOfDay = datesAdded.findIndex(
        (date) => date.day === shiftDate.getDay()
      );
      if (indexOfDay === -1) {
        datesAdded.push({
          day: shiftDate.getDay(),
          date: shiftDate.toDateString(),
        });
      } else {
        if (datesAdded[indexOfDay].date !== shiftDate.toDateString()) {
          return false;
        }
      }
      const dayIndex =
        (shiftDate.getDay() + 7 - getDayNumber(weekStartDay)) % 7; // Adjusted for start day
      return dayIndex >= 0 && dayIndex < 7;
    });
    return [...sortedPassedWeekShifts, ...clippedShifts];
  }, [sortedShifts, weekStartDay, sortedPassedWeekShifts]);

  const processedShifts = useMemo(() => {
    let shiftsWithMargin: any[] = [];
    let returnValue = [];
    returnValue = shifts.map((shift: any) => {
      const startDate = new Date(shift.startDateTime);
      const endDate = new Date(shift.endDateTime);

      const shiftDayIndex =
        (startDate.getDay() + 7 - getDayNumber(weekStartDay)) % 7;
      const shiftNextDayIndex =
        (startDate.getDay() + 8 - getDayNumber(weekStartDay)) % 7;

      const shiftHourIndex = startDate.getHours();
      const shiftEndHourIndex = overlappingDaysShifts.some(
        (overlappingShift: any) => overlappingShift.id === shift.id
      )
        ? endDate.getHours() - 1
        : -1;

      const shiftDurationInMinutes =
        (endDate.getTime() - startDate.getTime()) / (1000 * 60);
      const shiftHeight =
        (shiftDurationInMinutes / (24 * 60)) * (timeSlotHeight * 24);
      const shiftTop = (startDate.getMinutes() / 60) * 100;
      const shiftBottom = (endDate.getMinutes() / 60) * 100;

      // Find overlapping shifts
      const overlappingShifts = shifts.filter((otherShift: any) => {
        const otherStartTime = new Date(otherShift.startDateTime).getTime();
        const otherEndTime = new Date(otherShift.endDateTime).getTime();
        const startDateTime = new Date(shift.startDateTime).getTime();
        const endDateTime = new Date(shift.endDateTime).getTime();
        return (
          (otherStartTime >= startDateTime && otherStartTime < endDateTime) ||
          (otherEndTime > startDateTime && otherEndTime <= endDateTime) ||
          (otherStartTime <= startDateTime && otherEndTime >= endDateTime)
        );
      });

      // Sort by start time
      overlappingShifts.sort(
        (a: any, b: any) =>
          new Date(a.startDateTime).getTime() -
          new Date(b.startDateTime).getTime()
      );

      const shiftIndexInOverlapping = overlappingShifts.findIndex(
        (s: any) => s.id === shift.id
      );
      let modifierForShiftMargin = 1;

      let overlappingShiftMargin = 50;
      let overlappingShiftWidth = 100;

      // Calculate shift width and margin
      const overlappingShiftsCount = overlappingShifts.length;
      if (overlappingShiftsCount > 1) {
        // Total count of indexes less than the current shift index
        const totalCountOfIndexesLessThanCurrentShiftIndex =
          overlappingShifts.filter(
            (overlappingShift: any, index) => index < shiftIndexInOverlapping
          ).length;
        overlappingShifts.forEach((overlappingShift: any, index) => {
          if (overlappingShift.id === shift.id) {
            return;
          }
          const overlappingShiftIndexInOverlapping =
            overlappingShifts.findIndex(
              (otherOverlappingShift: any) =>
                overlappingShift.id === otherOverlappingShift.id
            ) ?? 0;
          if (overlappingShiftIndexInOverlapping !== -1) {
            if (overlappingShiftIndexInOverlapping < shiftIndexInOverlapping) {
              const isShiftsMarginAlreadySetForOverlappingShift =
                shiftsWithMargin.findIndex(
                  (shiftWithMargin: any) =>
                    shiftWithMargin.id ===
                    overlappingShifts[overlappingShiftIndexInOverlapping].id
                );
              if (isShiftsMarginAlreadySetForOverlappingShift === -1) {
                modifierForShiftMargin += 1;
              } else {
                const overlappingShift = shiftsWithMargin.find(
                  (shiftWithMargin: any) =>
                    shiftWithMargin.id ===
                    overlappingShifts[overlappingShiftIndexInOverlapping].id
                );

                overlappingShiftMargin = overlappingShift?.margin ?? 50;
                overlappingShiftWidth = overlappingShift?.width ?? 100;
                if (overlappingShiftMargin !== 50) {
                  if (modifierForShiftMargin === 1) {
                    modifierForShiftMargin =
                      overlappingShiftMargin / shiftSpacing + 1;
                  } else if (
                    modifierForShiftMargin <
                    overlappingShiftMargin / shiftSpacing + 1
                  ) {
                    modifierForShiftMargin =
                      overlappingShiftMargin / shiftSpacing + 1;
                  }
                } else if (
                  modifierForShiftMargin === 1 &&
                  index === totalCountOfIndexesLessThanCurrentShiftIndex - 1
                ) {
                  modifierForShiftMargin = -1;
                }
              }
            }
          }
        });
      }

      let changedMarginOfShift =
        shiftIndexInOverlapping > 0
          ? 100 / overlappingShiftsCount < 50
            ? modifierForShiftMargin !== -1
              ? shiftSpacing * modifierForShiftMargin
              : 50
            : modifierForShiftMargin !== -1 && overlappingShiftMargin !== 0
            ? shiftSpacing * modifierForShiftMargin
            : overlappingShiftMargin === 0 && overlappingShiftWidth === 50
            ? 50
            : shiftSpacing
          : 0;

      let shiftWidthPercent =
        overlappingShiftsCount > 1
          ? overlappingShiftMargin !== 50
            ? 100 - changedMarginOfShift
            : overlappingShiftsCount === 2
            ? 50
            : 100 - changedMarginOfShift
          : 100;

      if (changedMarginOfShift > 60) {
        changedMarginOfShift = shiftSpacing * 3;
        shiftWidthPercent = shiftSpacing * 2;
      }

      const isShiftsMarginAlreadySet = shiftsWithMargin.findIndex(
        (shiftWithMargin: any) => shiftWithMargin.id === shift.id
      );

      if (isShiftsMarginAlreadySet === -1) {
        if (
          overlappingShiftWidth === 50 &&
          shiftWidthPercent !== 50 &&
          shiftIndexInOverlapping > 0
        ) {
          const indexOfOverlappingShiftInShiftsWithMargin =
            shiftsWithMargin.findIndex(
              (shiftWithMargin: any) =>
                shiftWithMargin.id ===
                overlappingShifts[shiftIndexInOverlapping - 1].id
            );
          shiftsWithMargin[
            indexOfOverlappingShiftInShiftsWithMargin
          ].width = 100;
        }

        shiftsWithMargin = [
          ...shiftsWithMargin,
          {
            id: shift.id,
            margin: changedMarginOfShift,
            width: shiftWidthPercent,
          },
        ];
      }

      return {
        ...shift,
        shiftDayIndex,
        shiftHourIndex,
        shiftEndHourIndex,
        shiftNextDayIndex,
        shiftTop,
        shiftBottom,
        shiftWidthPercent,
        shiftHeight,
        shiftIndexInOverlapping,
        changedMarginOfShift,
      };
    });

    // Update shifts width based on shiftsWithMargin
    returnValue = returnValue.map((shift: any) => {
      const isShiftsMarginAlreadySet = shiftsWithMargin.findIndex(
        (shiftWithMargin: any) => shiftWithMargin.id === shift.id
      );
      if (isShiftsMarginAlreadySet !== -1) {
        const shiftWithMargin = shiftsWithMargin.find(
          (shiftWithMargin: any) => shiftWithMargin.id === shift.id
        );
        shift.shiftWidthPercent = shiftWithMargin.width;
        shift.changedMarginOfShift = shiftWithMargin.margin;
      }
      return shift;
    });

    return returnValue;
  }, [shifts, overlappingDaysShifts, weekStartDay, timeSlotHeight]);

  const calculateTodayPosition = () => {
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const currentDay = addDays(startingDay, i);
      if (isSameDay(today, currentDay)) {
        return i;
      }
    }
    return -1; // Default if not found
  };

  const todayPosition = calculateTodayPosition();

  const generateSkeletonVisibility = (
    hoursLength: number,
    seed: number
  ): boolean[] => {
    let lastSkeletonHour = -4;
    const visibilityArray = new Array(hoursLength).fill(false);

    // Seed-based randomization for different days
    const seededRandom = (hourIndex: number) => {
      return Math.abs(Math.sin((hourIndex + seed) * 1234)) % 1; // Seed affects randomization
    };

    for (let i = 0; i < hoursLength; i++) {
      if (i - lastSkeletonHour >= 6 && seededRandom(i) > 0.8) {
        visibilityArray[i] = true;
        lastSkeletonHour = i;
      }
    }

    return visibilityArray;
  };

  const [selectedShiftIndex, setSelectedShiftIndex] = useState<number | null>(
      null
  );
  const listRef = useRef(null);
  const [layerEl, setLayerEl] = useState<any>(null); // For handling overlapping shift popover on layer icon
  const isLayerPopoverOpen = Boolean(layerEl);
  const [hourString, setHourString] = useState<string>("");
  const [hourPassingShifts, setHourPassingShifts] = useState<any>([]);
  const openLayerPopover = (event: any, hourString: string, allPassingShifts:any) => {
    setHourString(hourString);
    setLayerEl(event.currentTarget);
    setHourPassingShifts(allPassingShifts);
  };
  const closeLayerPopover = () => {
    setSelectedShiftIndex(null);
    setLayerEl(null);
    setHourString("");
    setHourPassingShifts([]);
  };

  return [
    {
      anchorEl,
      selectedShift,
      currentTime,
      timeSlotHeight,
      weekStartDay,
      startingDay,
      currentDate,
      isWeekView,
      todayPosition,
      pastWeekShifts,
      processedShifts,
      shifts,
      userRoles,
      canCreateOrEditShift,
      visible,
      layerEl,
      listRef,
      selectedShiftIndex,
      isLayerPopoverOpen,
      hourString,
      hourPassingShifts,
    },
    {
      toggleVisibility,
      setVisible,
      generateSkeletonVisibility,
      openShiftPopover,
      openLayerPopover,
      closeLayerPopover,
      setSelectedShiftIndex,
    },
  ];
};

export default useGridSchedulerController;
