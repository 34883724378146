// TODO - Type 'any' needs to be fixed.
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	changeSelectedMenuItemId,
	closeNavigationDrawer,
	getCustomLogo,
	openNavigationDrawer,
} from "../../../../export/drawer";
import { MenuItems } from "../../../../export/drawer";
import { Drawer, DrawerHeader } from "../../../../export/drawer/styles";
import logo from "../assets/gig_and_take_icon@3x.png";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logoTitle: string = require("../assets/logo_wilya_dark_text.svg").default;

interface NavDrawerProps {
	keepSettingOpen: boolean;
	keepReportOpen?: boolean;
	keepHelpOpen?: boolean;
}

const NavDrawer: React.FC<NavDrawerProps> = ({
	keepSettingOpen,
	keepReportOpen = false,
	keepHelpOpen = false,
}) => {
	const dispatch = useDispatch();

	const [smallLogoUrl, setSmallLogoUrl] = useState("");
	const [largeLogoUrl, setLargeLogoUrl] = useState("");

	//TODO: Have to make changes redux to use IRootState as state type
	const open = useSelector((state: any) => state.navigationDrawer.isDrawerOpen);
	const selectedMenuItemId = useSelector(
		(state: any) => state.navigationDrawer.selectedMenuItemId
	);
	const customLogo = useSelector((state: any) => state.user.customLogo);
	const customizedBrandingEnabled = useSelector(
		(state: any) => state.user.userData.customizedBrandingEnabled
	);

	const handleDrawerOpen = open
		? closeNavigationDrawer()
		: openNavigationDrawer();

	const updateSelectedMenuItemId = (id: any) => {
		dispatch(changeSelectedMenuItemId(id));
	};

	useEffect(() => {
		if (Array.isArray(customLogo) && customLogo.length > 0) {
			const smallLogo =
				customLogo.find(
					(obj: { typeCode: string; url: string }) =>
						obj.typeCode === "smalllogo"
				) ?? "";
			const largeLogo =
				customLogo.find(
					(obj: { typeCode: string; url: string }) =>
						obj.typeCode === "largelogo"
				) ?? "";
			setSmallLogoUrl(smallLogo.url);
			setLargeLogoUrl(largeLogo.url);
		} else {
			setSmallLogoUrl("");
			setLargeLogoUrl("");
		}
	}, [customLogo]);

	useEffect(() => {
		if (customizedBrandingEnabled) {
			dispatch(getCustomLogo());
		}
	}, [customizedBrandingEnabled, dispatch]);

	return (
		<>
			{/* The App bar can be added here */}
			<Drawer
				variant='permanent'
				open={open}
			>
				<DrawerHeader>
					<IconButton
						disableRipple
						aria-label='open drawer'
						onClick={() => dispatch(handleDrawerOpen)}
						edge='start'
						sx={{
							color: "#18253E",
							padding: "0px !important",
							marginLeft: "-16px",
						}}
					>
						{/* Custom logo code here for open and closed state */}
						{smallLogoUrl !== "" && !open && !keepSettingOpen && (
							<img src={smallLogoUrl} alt='' height={32} />
						)}
						{largeLogoUrl !== "" && (open || keepSettingOpen) && (
							<img src={largeLogoUrl} height={32} alt='' />
						)}
						{/* Default gig and take logo state here */}
						{smallLogoUrl === "" && <img src={logo} alt='Wilya' height={32} />}
						{largeLogoUrl === "" && (
							<img
								src={logoTitle}
								alt='Wilya'
								height={16}
								style={{ marginLeft: "20px" }}
							/>
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<MenuItems
					open={open}
					keepSettingOpen={keepSettingOpen}
					keepReportOpen={keepReportOpen}
					keepHelpOpen={keepHelpOpen}
					selectedMenuItemId={selectedMenuItemId}
					updateSelectedMenuItemId={updateSelectedMenuItemId}
				/>
			</Drawer>
		</>
	);
};

export default NavDrawer;
